/* Import typeface */
@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@400;500;600;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Base styles */
html, body, #root, .App {
  height: 100%;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Rethink Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF3DD;
}

body.is-landing .App-header {
  opacity: 0;
  pointer-events: none;
}

body.summary-active {
  background-color: #42052B;
}

@supports (-webkit-touch-callout: none) {
  .navigation-area {
    transition: bottom 0.3s ease;
  }
}

/* New color variables */
:root {
  --color-background: #FFF3DD;
  --color-primary: #FF5757;
  --color-secondary: #42052B;
  --color-accent: #EBD2FE;
  --color-text: #4A0E4E;
}

/* Utility classes */
.bg-background { background-color: var(--color-background); }
.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-accent { background-color: var(--color-accent); }
.text-background { color: var(--color-background); }
.text-primary { color: var(--color-primary); }
.text-secondary { color: var(--color-secondary); }
.text-accent { color: var(--color-accent); }
.text-text { color: var(--color-text); }

